import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

const TermsAndConditions = () => {
  const intl = useIntl();
  return (
    <Layout contentType="internal">
      <SEO title={intl.formatMessage({ id: "terms_conditions.title" })} />
      <div className="container page-content">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <h1><FormattedMessage id="terms_conditions.title" /></h1>

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer velit metus, mattis ut sagittis eu, congue ac nisi. Vivamus faucibus justo ac pharetra gravida. Integer ut congue massa. Nullam varius, magna sit amet finibus molestie, massa magna sagittis mi, et laoreet augue nulla ut urna. Donec congue efficitur malesuada. Aliquam feugiat ligula in volutpat interdum. In dui nunc, euismod consequat enim id, vulputate sollicitudin libero. Donec lobortis placerat turpis, vitae accumsan nulla condimentum sit amet. Suspendisse pharetra congue erat. Proin nec lacus vitae massa mollis iaculis. Pellentesque euismod arcu ac purus cursus rutrum.</p>

            <h3>Donec eu luctus purus</h3>
            <p>Donec eu luctus purus. Cras at hendrerit urna, in imperdiet velit. Integer accumsan, odio vel mollis rhoncus, eros tortor pretium lacus, eu condimentum diam ipsum quis quam. In hac habitasse platea dictumst. Praesent a mi mollis, consectetur lectus at, dictum diam. Nullam eget neque vel elit finibus efficitur sed pretium nibh. Nunc tristique luctus eros, eu fermentum metus imperdiet sed. Morbi ultricies nulla in lacus imperdiet consequat. Nullam non scelerisque sem, vitae tincidunt magna. Maecenas semper gravida augue, ut sollicitudin nibh varius sed. Praesent gravida turpis ac turpis semper pellentesque. Donec lacus ante, rhoncus et mi nec, luctus rutrum ligula. Aenean ac lorem dignissim, hendrerit arcu quis, interdum lorem. Vestibulum mollis ex accumsan neque dictum, non sagittis Leo congue.</p>
            <p>Quisque varius leo eget sodales sollicitudin. Pellentesque congue cursus ante a placerat. Integer suscipit semper pharetra. Integer semper ultricies mauris, eget ultrices leo vestibulum sit amet. Aliquam eu sapien et ligula luctus ornare. Maecenas sem quam, consequat vitae pretium eget, blandit nec leo. Praesent nec ante egestas, tincidunt nunc vitae, vestibulum dui. Vivamus luctus quam vitae scelerisque porttitor. In nisl lacus, tempor sed mollis quis, malesuada ut urna.</p>

            <h3>Donec eu luctus purus</h3>
            <p>Sed luctus ultrices dignissim. Nam auctor nisl orci, eu scelerisque nisi vulputate sollicitudin. Nunc finibus hendrerit leo, sed venenatis nulla aliquet imperdiet. Aenean mattis suscipit dolor id pulvinar. Nam in dui euismod enim tincidunt luctus. Sed vitae urna vitae nunc viverra congue quis eu mi. Pellentesque porttitor nulla in mi bibendum, id ornare lorem fermentum. Aliquam ornare, sapien vel tempor tempor, mi nunc tincidunt dui, eu rutrum enim nibh vel diam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;</p>
            <p>
              Aliquam nisi purus, feugiat ut erat eu, posuere hendrerit est. Phasellus bibendum nunc a commodo sagittis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nam nec consectetur elit, nec malesuada magna. Aliquam lorem nibh, tincidunt ut dui eu, ornare feugiat nulla. Nunc sit amet vehicula augue, eget euismod magna. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris eget ligula massa. Nulla id velit non risus suscipit semper et eget sem. Ut mattis ante ligula, sed pulvinar metus aliquam eu. In vitae elit at velit ornare consectetur ut et ipsum. Quisque massa enim, dignissim imperdiet consectetur eu, sagittis eu orci. In gravida justo quis diam sagittis mattis. Maecenas ac arcu odio. Fusce imperdiet laoreet iaculis.
            </p>
            <p>
              Sed blandit lacus eget felis dictum congue eu eget nisl. Fusce scelerisque ipsum efficitur auctor semper. Vivamus vel risus eget risus tempus faucibus in eu diam. Aliquam ac nibh mi. Nulla facilisi. Donec id luctus nunc. Vivamus quis felis diam. Suspendisse massa turpis, commodo in ullamcorper mattis, rhoncus aliquet ex. Suspendisse a dui interdum, posuere quam non, consectetur odio. Aliquam erat volutpat. Mauris eleifend a sem in dapibus.
            </p>

            <h3>Donec eu luctus purus</h3>
            <p>Duis in tellus finibus, consectetur lacus lacinia, tincidunt nunc. Duis sit amet magna in orci mattis elementum ut eget orci. Morbi et mauris porttitor, eleifend ipsum quis, rhoncus ex. Donec eget lectus congue, malesuada neque interdum, tincidunt justo. Integer dolor ligula, tempor suscipit scelerisque in, iaculis eget leo. Sed non ex urna. Proin vel nisl sodales, posuere justo id, congue libero. Pellentesque fermentum venenatis malesuada. Mauris a metus quis nulla pellentesque euismod. Nunc dictum lacus vel viverra volutpat. Sed elementum velit neque, sed aliquet odio interdum at. Integer ultricies libero tortor, in laoreet dolor consequat quis.</p>
            <p>Nam lobortis rutrum leo, eget luctus nisl condimentum facilisis. Nullam porttitor, arcu eget vestibulum pellentesque, ligula lacus cursus tortor, eget porta nibh libero vitae ligula. Ut eu dapibus felis, vitae tristique odio. In condimentum nunc eu felis tempor placerat. Aliquam erat volutpat. Nam bibendum aliquet augue, id condimentum erat malesuada vitae. Pellentesque ultrices tempus est. Donec nibh dolor, semper sit amet nisl sed, rhoncus ornare eros. Aliquam finibus ligula metus, sit amet aliquam risus aliquet et. Nunc imperdiet posuere nunc ut posuere. Mauris fringilla luctus gravida. Integer convallis fermentum iaculis.</p>
            <p>Nam sed pharetra lacus. Morbi ornare egestas est in sagittis. Pellentesque feugiat quam metus, quis eleifend nisl sodales sed. Sed interdum pulvinar magna, eu rutrum lorem dictum eu. Cras id lorem mauris. Praesent fermentum efficitur lacus ullamcorper porttitor. Quisque cursus libero vel scelerisque placerat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec lacinia purus a ligula cursus consectetur. Aenean sed molestie enim.</p>
            <p>Donec vulputate dolor velit, id lobortis metus egestas non. Nam sit amet porta libero. Nam rutrum id lectus id iaculis. In accumsan, erat sed eleifend mollis, felis magna elementum ipsum, porta sagittis sapien nibh ut nisi. Integer ante nibh, commodo quis risus eget, eleifend lacinia tellus. Pellentesque eu neque laoreet, gravida justo id, interdum urna. In eget porta magna. Etiam id dui quam. Nulla lobortis viverra nibh non commodo. Mauris a nulla velit. Etiam feugiat vitae ligula vel blandit. In in ante eget diam vulputate rutrum vitae ac lacus.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermsAndConditions
